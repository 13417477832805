<template>
  <v-card :loading="loading" :disabled="loading" flat>
    <v-toolbar flat color="white" dense>
      <v-tabs v-if="record" v-model="tab" align-tabs="start">
        <v-tab color="primary">{{ $store.getters.translate("general_info") }}</v-tab>
      </v-tabs>
      <v-spacer/>
      <v-btn :disabled="loading" v-if="module_name === 'simpleinspections'" size="small" @click="$emit('showChooseTemplate')">
        {{ $store.getters.translate("contract") }}
        <v-icon color="primary" size="large" class="ml-2" icon="mdi-download"/>
      </v-btn>
      <v-btn v-if="canDelete" :disabled="loading" size="small" @click="$emit('archive')">
        {{ $store.getters.translate("archive") }}
        <v-icon color="red" size="large" class="ml-2" icon="mdi-archive"/>
      </v-btn>
      <v-btn v-if="canEdit" :disabled="loading" size="small" @click="$emit('update')">
        {{ $store.getters.translate("save") }}
        <v-icon color="primary" size="large" class="ml-2" icon="mdi-content-save"/>
      </v-btn>
    </v-toolbar>
    <v-window v-model="tab">
      <v-window-item>
        <v-card class="ml-5 mr-5" flat elevation="0" rounded="0">
          <v-card-text>
            <slot name="main_fields"/>
          </v-card-text>
        </v-card>
      </v-window-item>
    </v-window>
  </v-card>
</template>

<script>
import { useAbility } from '@casl/vue';

export default {
  props: ["record", "module_name", "loading", "model"],
  setup() {
    const { can } = useAbility()
    return { can }
  },
  data() {
    return {
      tab: 0,
    };
  },
  computed: {
    canEdit() {
      return this.can('edit', this.model)
    },
    canDelete() {
      return this.can('delete', this.model)
    },
  },
};
</script>
<style scoped>
.label-custom {
  border: 0px !important;
  font-style: italic;
}
.v-card {
  z-index: 1000 !important;
}
</style>